import { Link } from "react-router-dom";
import React from "react";
import styles from "./centros.module.css";
//import { useDispatch } from "react-redux";
//import { addDiets, getRecipes } from "../../redux/actions";

function Centros() {
  // const dispatch = useDispatch()

  //   function handleClick() {
  //     dispatch(getRecipes());
  //     dispatch(addDiets());
  //   }

  return (
    <div className={styles.container}>
      blas pascal
    </div>
  );
}

export default Centros;
