let profesionales = "Salud y bienestar en cada consejo. \nEncontrá al especialista que necesitas."

let comercios = 'Productos de calidad para cuidar de ti y tus seres queridos. \nPorque la salud es un compromiso diario.'

let centros = 'Una vida plena depende de ti y puede comenzar aquí. \nEncontrá el lugar indicado.'

let empleos = 'Abriendo puertas al mundo laboral de la salud. \nSumá tu CV o encontrá la persona adecuada.'

module.exports = {
    profesionales,
    comercios,
    centros,
    empleos
}

