import { Link } from "react-router-dom";
import React from "react";
import styles from "./empleos.module.css";
//import { useDispatch } from "react-redux";
//import { addDiets, getRecipes } from "../../redux/actions";

function Empleos() {
  // const dispatch = useDispatch()

  //   function handleClick() {
  //     dispatch(getRecipes());
  //     dispatch(addDiets());
  //   }

  return (
    <div className={styles.container}>
     nor
    </div>
  );
}

export default Empleos;
