import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
import styles from "./about.module.css";

export default function About() {

// const navigate = useNavigate()    


  return (
    <div className={styles.about}>
    
     
    </div>
  );
}
