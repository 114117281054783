import "./App.css";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Home from "./Components/Home/home";
import Nav from "./Components/Nav/nav"
import Profesionales from "./Components/Profesionales/profesionales"
import Comercios from "./Components/Comercios/comercios"
import Centros from "./Components/Centros/centros"
import About from "./Components/About/about";
import Publicidad from "./Components/Publicidad/publicidad";
import Empleos from "./Components/Empleos/empleos";

function App() {

  const [access, setAccess] = useState(false)
  
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    !access && navigate('/')
  }, [access])


  return (
    <div className="App">
      <Nav  />
      
      <div className="centro">
      {/* <Publicidad /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profesionales" element={<Profesionales />} />
          {/* <Route path='/recipes/:id' element={<RecipeDetail />}/> */}
          <Route path='/comercios' element={<Comercios />} />
          {/* <Route path="/recipes/update/:id" element={<Update />} /> */}
          <Route path='/about' element={<About />} />
          <Route path='/centros' element={<Centros />} />
          <Route path='/empleos' element={<Empleos />} />
        </Routes>
       
      </div>
      <Publicidad />
    </div>
  );
}

export default App;
