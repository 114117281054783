import styles from "./publicidad.module.css";



function Publicidad() {

  
    return (
        <div className={styles.container}>Publicidades</div>
    )
}

export default Publicidad