import { Link } from "react-router-dom";
import React from "react";
import styles from "./home.module.css";
import {profesionales, comercios, centros, empleos} from "../../Helpers/textos"
//import Publicidad from "../Publicidad/publicidad";
//import { useDispatch } from "react-redux";
//import { addDiets, getRecipes } from "../../redux/actions";

function Home() {
  // const dispatch = useDispatch()

  //   function handleClick() {
  //     dispatch(getRecipes());
  //     dispatch(addDiets());
  //   }

  return (
    <div className={styles.container}>
      <div className={styles.pub1}>Publicidad 1</div>
      <div className={styles.listado}>
        <div className={styles.opciones}>
        
          <div className={styles.profesionales}>
          <Link to={'/profesionales'} style={{"textDecoration": "none"}}>
            <div className={styles.iconosProf}>
             <img src="src/profesionales.png" alt="Profesionales" />
              PROFESIONALES DE LA SALUD
            </div>
            <div className={styles.textos}>
              <p>{profesionales}</p>
            </div>
            </Link>
          </div>
       
        
          <div className={styles.centros}>
          <Link to={'/profesionales'} style={{"textDecoration": "none"}}>
            <div className={styles.iconosCen}>
              <img src="src/centros.png" alt="Centros" />
              CENTROS ESPECIALIZADOS
            </div>
            <div className={styles.textos}>
              <p>{centros}</p>
            </div>
            </Link>
          </div>
        
        
          <div className={styles.comercios}>
          <Link to={'/profesionales'} style={{"textDecoration": "none"}}>
            <div className={styles.iconosCom}>
              <img src="src/comercios.png" alt="Comercios" />
              COMERCIOS ADHERIDOS
            </div>
            <div className={styles.textos}>
              <p>{comercios}</p>
            </div>
            </Link>
          </div>
        
        
      </div>
      
          <div className={styles.empleos}>
          <Link to={'/profesionales'} style={{"textDecoration": "none"}}>
            <div className={styles.iconosEmp}>
              BOLSA DE EMPLEO
            </div>
            <div className={styles.textosEmp}>
              <p>SUMA TU CV</p>
            </div>
            </Link>
          </div>
        
        </div>
      <div className={styles.pub2}>Publicidad 2</div>
    </div>
  );
}

export default Home;
