import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import styles from "./profesionales.module.css";
//import { useDispatch } from "react-redux";
import Loading from "../Loading/loading";
import Profesional from "../Profesional/profesional";

const profesionales = require('../../baseDatos.json');

function Profesionales() {

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate()

  useEffect(() => {
    if (profesionales.length > 0)  {
      setIsLoading(false);
    }
  }, [profesionales]);

  function handleReturn() {
    navigate(-1);
  }

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
    <div className={styles.container}>
      <div className={styles.pub1}>Publicidad 1</div>
      <div className={styles.profesionales}>
      {profesionales ? (
              profesionales.map((r) => {
                return (
                  <Profesional 
                    key={r.id && r.id}
                    id={r.id && r.id}
                    nombre={r?.nombre}
                    imagen={r?.imagen}
                    direccion={r?.direccion}
                    contacto={r?.contacto}
                    horarios={r?.horarios}
                  />
                );
              })
            ) : (
              <p>No hay nada</p>
            )}
      </div>
      <div className={styles.pub2}>Publicidad 2</div>
      <button onClick={handleReturn}></button>
    </div>
      )}
   
    </div>
  );
}

export default Profesionales;
