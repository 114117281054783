import styles from "./profesional.module.css";



function Profesional(props) {

  
    return (
      
      <div className={styles.container}>
       
        <div className={styles.detalles}>
          <div className={styles.iconos}>
            <img src={props?.imagen} alt={props.nombre} /> 
          </div>
          <div className={styles.textos}>
            <b className={styles.nombre}>{props.nombre}</b>
            <b>Horarios</b> {props?.horarios?.apertura} a {props?.horarios?.cierre}<br/>
            <b>Dirección:</b> {props?.direccion?.calle}, {props?.direccion?.ciudad} <br/>
            <b>Teléfono:</b> {props?.contacto?.telefono} <br/>
            <b>Email:</b> {props?.contacto?.email} <br/>     
          </div>  
        </div> 
       
      </div>
    );
  }
  
  export default Profesional;
  