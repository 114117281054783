// import { useState } from "react";
import { connect, useDispatch } from "react-redux";
//  import { useLocation, useNavigate } from "react-router-dom";
// import { emptyMessage, filterRecipes, sortRecipes } from "../../redux/actions";

import styles from "./nav.module.css";
import { Link } from "react-router-dom";

export function Nav(props) {

  return (
    <div className={styles.nav}>
      <Link to={'/'} style={{"textDecoration": "none"}}>
     <img className={styles.logo} src="src/logo.png" alt="Logo" />
     <img className={styles.nombre} src="src/nombre.png" alt="Nombre" />
     </Link>
    </div>
  );
}

export function mapStateToProps(state) {
  return {
    diets: state.diets,
  };
}

export default connect(mapStateToProps, null)(Nav);